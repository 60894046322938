/**In this class functional hooks for tracker or other third party will should be placed.*/
export class Hooks {
  /**
   * Function for adding a "clarity" hook see https://clarity.microsoft.com/.
   * @param projectId tracking id of the clarity project
   */
  static addClarityScript(projectId: string) {
    const script = document.createElement('script');
    script.innerHTML = `(function (c, l, a, r, i, t, y) {
          c[a] =
            c[a] ||
            function () {
              ;(c[a].q = c[a].q || []).push(arguments)
            }
          t = l.createElement(r)
          t.src = "https://www.clarity.ms/tag/" + i
          y = l.getElementsByTagName(r)[0]
          y.parentNode.insertBefore(t, y)
        })(window, document, "clarity", "script", "${projectId}")`;
    script.async = true;
    document.head.appendChild(script);
  }

  /**
   * Function for adding a "trackboxx" hook see https://app.trackboxx.com/.
   * @param projectId tracking id of the trackboxx project
   */
  static addTrackBox(projectId: string) {
    const script = document.createElement('script');
    script.innerHTML = `(function(d, s, id, w, f){
      w[f] = w[f] || function() {
          (w[f].q = w[f].q || []).push(arguments)
      };
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)){ return; }
      js = d.createElement(s); js.id = id;
      js.onload = function(){
          document.dispatchEvent(new Event('tbScriptLoaded'));
      };
      js.async = true;
      js.src = "https://cdn.trackboxx.info/p/tracker.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'trackboxx-script', window, 'trackboxx'));
    trackboxx('set', 'siteId', "${projectId}");
    trackboxx('trackPageview');`;
    script.async = true;
    document.head.appendChild(script);
  }
}
