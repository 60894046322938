<div class="grid-container">
  <iv-dialog-header title="{{ data.title }}" (closeClicked)="onClose()"></iv-dialog-header>
  <mat-dialog-content>
    <p class="message">{{ data.message }}</p>
  </mat-dialog-content>
  <iv-dialog-footer>
    <iv-button
      class="button1"
      [iconName]="data.button1.iconName"
      [stroked]="data.button1.stroked"
      [warn]="data.primaryPalette === 'warn'"
      [mat-dialog-close]="data.button1.returnValue"
      [cdkFocusInitial]="data.button1.default"
      >{{ data.button1.text }}</iv-button
    >
    <div class="stretch"></div>
    <iv-button
      class="button2"
      *ngIf="data.button2"
      [warn]="data.primaryPalette === 'warn'"
      [iconName]="data.button2.iconName"
      [stroked]="data.button2.stroked"
      [mat-dialog-close]="data.button2.returnValue"
      [cdkFocusInitial]="data.button2.default"
      tabindex="1"
      >{{ data.button2.text }}
    </iv-button>
  </iv-dialog-footer>
</div>
