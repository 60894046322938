import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { AppConfigService } from '@healthycloud/lib-app-config'

/**
 * Provides access to the userInfo-Endpoint of the TGP. Allows to check the login-state and provides convenience functions
 * to access user data.
 * TODO: Add function to fetch the user data of the logged in user.
 */
@Injectable({
    providedIn: 'root',
})
export class UserInfoService {
    constructor(
        private appConfigService: AppConfigService,
        private http: HttpClient
    ) {}

    /**
     * Checks if user is logged in according to the TGP
     * @returns Observable<boolean>
     */
    public isLoggedIn(): Observable<boolean> {
        return this.http.get<boolean>(`${this.appConfigService.tgpUrl}/isloggedin`, {
            withCredentials: true,
        })
    }
}
