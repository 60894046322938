<div class="dialog-title">
  <div class="title">
    <h2>{{ title }}</h2>
    <p class="mat-body-1" *ngIf="subTitle !== ''">{{ subTitle }}</p>
  </div>
  <button *ngIf="showClose" class="dialog-cross-icon" mat-icon-button aria-label="Dialog schließen" mat-dialog-close tabindex="-1" (click)="closeClicked.emit()">
    <mat-icon fontIcon="fa-xmark"></mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
