import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'iv-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [NgIf, MatButton, NgClass, MatIcon, NgTemplateOutlet],
})
export class ButtonComponent {
  @Input() disabled: boolean = false;
  @Input() noText: boolean = false;
  @Input() stroked: boolean = false;
  @Input() warn: boolean = false;
  @Input() secondary: boolean = false;
  @Input() iconName: string = '';
  /**overrides the click event emitter */
  @Output() click: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  /**
   * cdkFocusInitial specifies the element that will receive focus upon initialization of the region. @see https://material.angular.io/cdk/a11y/overview
   * We forward this attribute to the material button to be able to set this attribute from the parent component.
   */
  @Input() cdkFocusInitial: boolean = false;

  getClass(): string[] {
    let aReturn = ['iv-button'];
    if (this.noText) aReturn.push('no-text');
    if (this.secondary) aReturn.push('secondary');
    return aReturn;
  }

  /**
   * Helper method to overide the click event to stop the propagation from child button mouse events
   * @param event event to propagate to the parent by reemitting it, but stopping it for others.
   */
  onClick(event: MouseEvent) {
    this.click.emit(event);
    event.stopPropagation();
  }
}
