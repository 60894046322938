<span class="{{iconClass}}">
  <img src="{{ imageUrl }}" alt="icon" class="icon" />
</span>
<span class="sb-text">
  <div class="sb-title" matSnackBarLabel>{{ data.title }}</div>
  <div class="sb-label" matSnackBarLabel>{{ data.message }}</div>
</span>
<span class="sb-action" matSnackBarActions>
  <button mat-icon-button matSnackBarAction (click)="snackBarRef.dismissWithAction()"><mat-icon fontIcon="fa-xmark"></mat-icon></button>
</span>
