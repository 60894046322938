import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, isDevMode } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterOutlet } from '@angular/router';
import { AppConfigService } from '@healthycloud/lib-app-config';

import { Hooks } from './ivp/hooks/hooks';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { MessageBoxComponent } from './shared/dialogs/messagebox/messagebox.component';
import { IAppConfigCustomValues } from './shared/models/appConfigCustomValues';

@Component({
  selector: 'iv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [SpinnerComponent, RouterOutlet],
})
export class AppComponent {
  title: string = 'Intervisionsportal';

  rootClassName: string = 'root';
  ivpTheme: string = 'ivp';
  blueTheme: string = 'blue';
  baseClassName: string = this.rootClassName + ' ' + this.ivpTheme;
  className: string = this.baseClassName;

  isDarkThemeSelected: boolean = false;

  constructor(
    private overlayContainer: OverlayContainer,
    private snackBar: MatSnackBar,
    private iconRegistry: MatIconRegistry,
    private configService: AppConfigService<IAppConfigCustomValues>
  ) {
    // initialize hooks
    if (!isDevMode()) {
      const customValues: IAppConfigCustomValues | null = this.configService.getCustomValues('iv-frontend');
      if (customValues) {
        Hooks.addClarityScript(customValues.clarityId);
        Hooks.addTrackBox(customValues.trackboxxId);
      } else {
        console.warn('Can not read custom values from config');
      }
    }

    // initialize overlays like dialogs also with the correct theme
    this.overlayContainer.getContainerElement().classList.add(this.ivpTheme);
    this.iconRegistry.setDefaultFontSetClass('fa-regular');
  }

  ngAfterViewInit() {
    MessageBoxComponent.setSnackBar(this.snackBar);
  }

  // TODO Keep this for later use!
  // @HostListener('window:keydown', ['$event.key'])
  // next(key: string) {
  //   this.isDarkThemeSelected = key === 'b';
  //   // change the theme for the root element
  //   this.className = this.isDarkThemeSelected ? this.rootClassName + ' ' + this.blueTheme : this.baseClassName;
  //   // change the theme for the overlay element
  //   let overLayContainerElement: HTMLElement = this.overlayContainer.getContainerElement();
  //   if (this.isDarkThemeSelected) {
  //     overLayContainerElement.classList.replace(this.ivpTheme, this.blueTheme);
  //   } else {
  //     overLayContainerElement.classList.replace(this.blueTheme, this.ivpTheme);
  //   }
  // }
}
