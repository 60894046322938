import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http'
import { inject } from '@angular/core'
import { AppConfigService } from '@healthycloud/lib-app-config'
import { catchError, retry, throwError } from 'rxjs'

import { AuthService } from './auth.service'

/**
 * Interceptor function to handle Token Gateway Proxy (TGP) authorization.
 *
 * We only want to intercept requests that aim to our TGP.
 * If the request URL does not contain the configured TGP URL we skip the interception.
 *
 * @param request - The request to be intercepted.
 * @param next - The next interceptor or backend handler in the chain.
 * @returns the intercepted Observable of HTTP events.
 */
export const TgpInterceptor: HttpInterceptorFn = (request, next) => {
    const appConfigService: AppConfigService = inject(AppConfigService)

    const skipIntercept: boolean = !request.url.includes(appConfigService.tgpUrl)

    if (skipIntercept) {
        return next(request)
    }

    return next(request).pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
            if (error.status === 401 || error.status === 400) {
                console.warn("TGP-Interceptor: Caught error", error);
                location.reload()
            }
            if (error.status == 302) {
                // When the user is not logged in API calls are redirected to IDP-login.
                // So we try to tell the AuthService to logout.
                console.warn("TGP-Interceptor: Caught error", error);
                const authService: AuthService = inject(AuthService)
                authService.handleExpiredSession()
            }
            return throwError(() => error)
        })
    )
}
