import { Component } from '@angular/core';
import { MatDialogActions } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';

@Component({
  selector: 'iv-dialog-footer',
  templateUrl: './dialog-footer.component.html',
  styleUrls: ['./dialog-footer.component.scss'],
  standalone: true,
  imports: [MatDivider, MatDialogActions],
})
export class DialogFooterComponent {}
