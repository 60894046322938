import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

import { ButtonComponent } from '../../components/button/button.component';
import { DialogFooterComponent } from '../../components/dialog-footer/dialog-footer.component';
import { DialogHeaderComponent } from '../../components/dialog-header/dialog-header.component';
import { SnackBarComponent, SnackBarData } from '../../components/snack-bar/snack-bar.component';

export enum MessageBoxType {
  Error,
  Success,
  Warning,
  Confirm,
}

export type MessageBoxReturnType = 'OK' | 'YES' | 'NO' | 'RETRY' | 'CANCEL' | 'CONTINUE';
export type MessageBoxPrimaryPalette = 'primary' | 'warn';

export interface MessageBoxButton {
  text: string;
  iconName: string;
  default: boolean;
  stroked: boolean;
  returnValue: MessageBoxReturnType;
}

export interface MessageBoxData {
  messageBoxType: MessageBoxType;
  title: string;
  message: string;
  button1: MessageBoxButton;
  button2?: MessageBoxButton;
  primaryPalette: MessageBoxPrimaryPalette;
}

/**
 * Builds the data for the delete-group-confirmation dialog.
 * @param groupName The group name to build the dialog for.
 * @returns The MessageBoxData.
 */
export const getMessageBoxDataDeleteGroup = (groupName: string): MessageBoxData => {
  return {
    title: 'Wollen Sie die Gruppe wirklich löschen',
    message: `Gruppe "${groupName}"\n\nSie haben danach keinen Zugriff mehr auf Protokolle, Dateien oder Intervisionen. Die Gruppe wird für alle Teilnehmer gelöscht und ist danach nicht mehr verfügbar. Ihre Aktion kann nicht rückgängig gemacht werden. Sie können jederzeit eine neue Gruppe erstellen.`,
    button1: { text: 'Abbrechen', iconName: 'fa-xmark', stroked: true, default: true, returnValue: 'CANCEL' },
    button2: { text: 'Löschen', iconName: 'fa-trash-can', stroked: false, default: false, returnValue: 'OK' },
    primaryPalette: 'warn',
    messageBoxType: MessageBoxType.Confirm,
  };
};

/**
 * Builds the data for the delete-event-confirmation dialog.
 * @param eventName The event name to build the dialog for.
 * @returns The MessageBoxData.
 */
export const getMessageBoxDataDeleteEvent = (eventName: string): MessageBoxData => {
  return {
    title: 'Wollen Sie die Intervision wirklich löschen',
    message: `Intervision "${eventName}"\n\nSie haben danach keinen Zugriff mehr auf Protokolle oder Dateien dieser Intervision. Die Intervision wird für alle Teilnehmer gelöscht und ist danach nicht mehr verfügbar. Ihre Aktion kann nicht rückgängig gemacht werden. Sie können jederzeit eine neue Intervision erstellen.`,
    button1: { text: 'Abbrechen', iconName: 'fa-xmark', stroked: true, default: true, returnValue: 'CANCEL' },
    button2: { text: 'Löschen', iconName: 'fa-trash-can', stroked: false, default: false, returnValue: 'OK' },
    primaryPalette: 'warn',
    messageBoxType: MessageBoxType.Confirm,
  };
};

@Component({
  selector: 'iv-messagebox',
  templateUrl: './messagebox.component.html',
  styleUrls: ['./messagebox.component.scss'],
  standalone: true,
  imports: [ButtonComponent, DialogFooterComponent, DialogHeaderComponent, MatDialogClose, MatDialogContent, NgIf],
})
export class MessageBoxComponent {
  static snackBar: MatSnackBar;

  static snackBarDuration: number = 5000;

  static setSnackBar(snackBar: MatSnackBar) {
    MessageBoxComponent.snackBar = snackBar;
  }

  constructor(public dialogRef: MatDialogRef<MessageBoxComponent>, @Inject(MAT_DIALOG_DATA) public data: MessageBoxData) {
    dialogRef.disableClose = true;
    dialogRef.addPanelClass('messagebox-override');
  }

  /**Handles the close event by the cross icon of the dialog header */
  onClose() {
    this.dialogRef.close(this.data.button1.returnValue);
  }

  // static functions ####################
  static onError = (message: string, title: string = 'Es ist ein Fehler aufgetreten'): Observable<MatSnackBarDismiss> => {
    const data: SnackBarData = {
      messageBoxType: MessageBoxType.Error,
      title,
      message: message,
    };
    console.error(title + ':', message);
    const config: MatSnackBarConfig = { duration: MessageBoxComponent.snackBarDuration, data, panelClass: 'snackbar-override' };
    const snackBarRef = MessageBoxComponent.snackBar.openFromComponent(SnackBarComponent, config);
    return snackBarRef.afterDismissed();
  };

  static onSuccess = (message: string, title: string = 'Erfolg'): Observable<MatSnackBarDismiss> => {
    const data: SnackBarData = {
      messageBoxType: MessageBoxType.Success,
      title,
      message: message,
    };
    console.info(title + ':', message);
    const config: MatSnackBarConfig = { duration: MessageBoxComponent.snackBarDuration, data, panelClass: 'snackbar-override' };
    const snackBarRef = MessageBoxComponent.snackBar.openFromComponent(SnackBarComponent, config);
    return snackBarRef.afterDismissed();
  };

  static onWarning = (message: string, title: string = 'Warnung'): Observable<MatSnackBarDismiss> => {
    const data: SnackBarData = {
      messageBoxType: MessageBoxType.Warning,
      title,
      message: message,
    };
    console.warn(title + ':', message);
    const config: MatSnackBarConfig = { duration: MessageBoxComponent.snackBarDuration, data, panelClass: 'snackbar-override' };
    const snackBarRef = MessageBoxComponent.snackBar.openFromComponent(SnackBarComponent, config);
    return snackBarRef.afterDismissed();
  };
}
