import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import * as de from '@angular/common/locales/de';
import { APP_INITIALIZER, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppConfigService } from '@healthycloud/lib-app-config';
import { Observable, of, switchMap, take, tap, throwError } from 'rxjs';

import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { AuthService } from './app/shared/auth-lib/auth.service';
import { TgpInterceptor } from './app/shared/auth-lib/tgp.interceptor';
import { UserInfoService } from './app/shared/auth-lib/user-info.service';
import { LoaderInterceptor } from './app/shared/interceptors/loader.interceptor';
import { MaterialModule } from './app/shared/material.module';
import { UserInfoLoaderService } from './app/shared/services/user-info-loader.service';

function doLoginWithReturnUrl(configService: AppConfigService) {
  const currentUrl = location.href;
  let redirect = '';
  if (currentUrl.startsWith('http')) {
    // keep current URL as redirect after login
    redirect = `?redirectUrl=${encodeURIComponent(currentUrl)}`;
  } else {
    console.log("Main: Won't redirect with invalid returnUrl");
  }
  console.log('Main: Setting location-href:', `${configService.loginUrl}${redirect}`, location.href);
  location.href = `${configService.loginUrl}${redirect}`;
}

function initApp(
  configService: AppConfigService,
  loaderService: UserInfoLoaderService,
  userInfoService: UserInfoService,
  authService: AuthService
): () => Observable<any> {
  return () =>
    configService.initialize().pipe(
      switchMap(_cfg => {
        // when config is loaded, check loginstate
        return userInfoService.isLoggedIn().pipe(
          switchMap(loggedIn => {
            console.log('Main: Init - logged in: ', loggedIn, location.href);
            if (loggedIn) {
              registerLocaleData(de.default); //Then register the language

              authService.onSessionExpired.pipe(take(1)).subscribe(_ => {
                console.log('Main: got SessionExpired');
                doLoginWithReturnUrl(configService);
              });
              // when logged in, load userInfo
              return loaderService.loadUserInfo();
            } else {
              // not logged in -> redirect to login
              doLoginWithReturnUrl(configService);

              return throwError(() => 'Not logged in');
            }
          })
        );
      })
    );
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptors([TgpInterceptor]), withInterceptorsFromDi()),
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [AppConfigService, UserInfoLoaderService, UserInfoService, AuthService],
    },
    { provide: LOCALE_ID, useValue: 'de-DE' }, //Add the id and the language code here

    importProvidersFrom(BrowserModule, MaterialModule, AppRoutingModule, MatToolbarModule),
    provideAnimations(),
  ],
}).catch(err => console.error(err));
