import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';

import { StateService } from '../../core/services/state.service';
import { UserInfoRestService } from '../rest-services/user-info-rest.service';

@Injectable({
  providedIn: 'root',
})
export class UserInfoLoaderService {
  constructor(private userInfoRestService: UserInfoRestService, private stateService: StateService) {}

  /**
   * Get user info and save it in stateService.
   * @returns Observable<User>
   */
  public loadUserInfo(): Observable<boolean> {
    return this.userInfoRestService.getUserInfo().pipe(
      catchError(_ => of(undefined)),
      map(userInfo => {
        console.log('loadUserInfo()', userInfo);
        if (userInfo) {
          console.log('putting into stateservice', userInfo);
          this.stateService.setUserInfo(userInfo);
        }
        return userInfo !== undefined;
      })
    );
  }
}
