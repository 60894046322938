import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { Subscription } from 'rxjs';

import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'iv-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
  imports: [NgIf, MatProgressSpinner],
})
export class SpinnerComponent implements OnInit, OnDestroy {
  showSpinner: boolean = false;
  private loaderSubscription: Subscription | undefined = undefined;

  constructor(public loader: LoaderService) {}

  ngOnInit(): void {
    this.loaderSubscription = this.loader.isLoading$.subscribe({
      next: isLoading => {
        this.showSpinner = isLoading;
        if (this.showSpinner) {
          document.addEventListener('click', this.disableClickAndTouchEvents, { capture: true });
          document.addEventListener('dblclick', this.disableClickAndTouchEvents, { capture: true });
          document.addEventListener('mousedown', this.disableClickAndTouchEvents, { capture: true });
          document.addEventListener('touchstart', this.disableClickAndTouchEvents, { capture: true });
        } else {
          document.removeEventListener('click', this.disableClickAndTouchEvents, { capture: true });
          document.removeEventListener('dblclick', this.disableClickAndTouchEvents, { capture: true });
          document.removeEventListener('mousedown', this.disableClickAndTouchEvents, { capture: true });
          document.removeEventListener('touchstart', this.disableClickAndTouchEvents, { capture: true });
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.loaderSubscription?.unsubscribe();
  }

  /** handler to disable all mouse and touch events while the spinner is running */
  disableClickAndTouchEvents(event: MouseEvent | TouchEvent) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();
  }
}
