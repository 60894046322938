import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatDialogClose } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'iv-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  standalone: true,
  imports: [NgIf, MatIconButton, MatDialogClose, MatIcon, MatDivider],
})
export class DialogHeaderComponent {
  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Input() showClose: boolean = true;
  @Output() closeClicked = new EventEmitter<void>();
}
