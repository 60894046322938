import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { IUserDetailed } from '../../shared/models/userDetailed';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private userInfo$ = new BehaviorSubject<IUserDetailed>({ id: '' } as IUserDetailed);
  public hasCheckedSurveyDuringSession = false;

  constructor() {}

  /**
   * Get user info  observable.
   * @returns user info or undefined observable
   */
  public getUserInfo$(): Observable<IUserDetailed> {
    return this.userInfo$.asObservable();
  }

  /**
   * Set user info.
   * @param user user info
   */
  public setUserInfo(user: IUserDetailed) {
    console.log('setting userinfo', user);
    this.userInfo$.next(user);
  }
}
