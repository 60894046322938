import { LOCALE_ID, NgModule } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { getDeDePaginatorIntl } from './i18n/de-de-paginator-intl';

/** date formats for date inputs*/
export const DATE_FORMATS = {
  parse: {
    dateInput: 'dd.MM.yyyy',
  },
  display: {
    dateInput: 'dd.MM.yyyy',
    monthYearLabel: 'MMMM yyyy',
    dateA11yLabel: 'DD',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

@NgModule({
  // TODO move providers to better place
  providers: [
    { provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: MatPaginatorIntl, useValue: getDeDePaginatorIntl() },
    { provide: LOCALE_ID, useValue: 'de-DE' }, // for German translation. Ignore this if not needed.
  ],
})
export class MaterialModule {}
