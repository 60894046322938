import { Component, Inject, ViewEncapsulation, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef, MatSnackBarLabel, MatSnackBarActions, MatSnackBarAction } from '@angular/material/snack-bar';
import { MessageBoxType } from '../../dialogs/messagebox/messagebox.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

export interface SnackBarData {
  messageBoxType: MessageBoxType;
  title: string;
  message: string;
}

@Component({
  selector: 'iv-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatIcon, MatIconButton, MatSnackBarAction, MatSnackBarActions, MatSnackBarLabel],
})
export class SnackBarComponent {
  public imageUrl: string = 'assets/images/notifications/';
  public iconClass: string = 'sb-icon ';

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) {
    let imageFileName;
    let iconSubClass;
    switch (data.messageBoxType) {
      case MessageBoxType.Success:
        imageFileName = 'success.svg';
        iconSubClass = 'success';
        break;
      case MessageBoxType.Warning:
        imageFileName = 'warning.svg';
        iconSubClass = 'warning';
        break;
      case MessageBoxType.Error:
        imageFileName = 'error.svg';
        iconSubClass = 'error';
        break;
      default:
        console.error('Icon for message box is not defined');
    }
    this.imageUrl = this.imageUrl + imageFileName;
    this.iconClass = this.iconClass + iconSubClass;
  }
  snackBarRef = inject(MatSnackBarRef);
}
