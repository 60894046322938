import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { AppConfigService } from '@healthycloud/lib-app-config';
import { Subscription, interval } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  public readonly LOGIN_URL = () => this.appConfigService.loginUrl;

  private readonly LOGOUT_URL = () => `${this.appConfigService.tgpUrl}/logout`;
  private readonly TIMEOUT_COOKIE_KEY = () => this.appConfigService.sessionTimeoutCookieName;
  private readonly TIMOUT_INTERVAL = () => this.appConfigService.sessionTimeoutCheckInterval;
  private readonly TIMEOUT_LIMIT = () => this.appConfigService.sessionTimeoutLimit;

  private checkTimeoutSubscription?: Subscription;

  public onSessionExpired: EventEmitter<boolean> = new EventEmitter();

  constructor(private appConfigService: AppConfigService) {
    console.log('constructing AuthService', appConfigService.loginUrl);
    if (!this.checkTimeoutSubscription) {
      this.checkTimeoutSubscription = interval(this.TIMOUT_INTERVAL()).subscribe(() => this.checkTimeOut());
    }
  }

  ngOnDestroy() {
    this.checkTimeoutSubscription?.unsubscribe();
  }

  /**
   * Extracts the session timeout from cookies.
   *
   * @returns the session timeout as a Date object or false if no session cookie is given.
   */
  public getSessionTimeOut(): false | Date {
    const cookies: string = document.cookie;
    const timeout_cookie: string[] = cookies
      .split(';')
      .filter(string => {
        const aRet = string.includes(this.TIMEOUT_COOKIE_KEY()); //sssssssssssssssss
        console.log('AUTHAUTH aRet1', aRet, string, this.TIMEOUT_COOKIE_KEY());
        return aRet;
      })
      .map(string => {
        const aRet = string.split(this.TIMEOUT_COOKIE_KEY() + '=')[1];
        console.log('AUTHAUTH aRet2', aRet, string);
        return aRet;
      });

    const value: string = decodeURIComponent(timeout_cookie[0]);
    console.log('AuthService cookies: ', cookies);
    console.log('AuthService cookie and value: ', timeout_cookie[0], value);

    if (!value) {
      console.error('AuthService: no timeout found', this.TIMEOUT_COOKIE_KEY());
      return false;
    }

    return new Date(value + ' UTC');
  }

  /**
   * Checks the remaining time for the session timeout cookie.
   * @returns true if the remaining time for the session timeout cookie is greater than or equal to the limit, otherwise returns false.
   */
  public checkSessionTimeOut(): boolean {
    const timeout: false | Date = this.getSessionTimeOut();
    const now: Date = new Date();
    console.log('Auth-Service: ', timeout);
    if (!!timeout) {
      console.log('Auth-Service: ', timeout.getTime() - now.getTime(), this.TIMEOUT_LIMIT());
    }
    return !!timeout && timeout.getTime() - now.getTime() >= this.TIMEOUT_LIMIT();
  }

  // private doLoginWithReturnUrl() {
  //   const currentUrl = location.href;
  //   let redirect = '';
  //   if (currentUrl.startsWith('http')) {
  //     // keep current URL as redirect after login
  //     redirect = `?redirectUrl=${encodeURIComponent(currentUrl)}`;
  //   } else {
  //     console.log("Auth: Won't redirect with invalid returnUrl");
  //   }
  //   console.log('Auth: Setting location-href:', `${this.appConfigService.loginUrl}${redirect}`, location.href);
  //   location.href = `${this.appConfigService.loginUrl}${redirect}`;
  // }

  /**
   * Logout user
   */
  public logout() {
    if (this.checkSessionTimeOut()) {
      console.warn('Auth-Service: logout via logoutURL');
      location.href = this.LOGOUT_URL();
    } else {
      console.warn('Auth-Service: logout via loginURL');
      location.href = this.LOGIN_URL();
    }
  }

  /**
   * Handles an expired session.
   *
   * If the app configuration service indicates that the session expired event should be dispatched,
   * this method calls the dispatchSessionExpiredEvent() function. Otherwise, it calls the logout() function.
   */
  public handleExpiredSession() {
    if (this.appConfigService.shouldDispatchSessionExpiredEvent) {
      this.dispatchSessionExpiredEvent();
    } else {
      this.logout();
    }
  }

  /**
   * Dispatches a session expired event.
   */
  private dispatchSessionExpiredEvent() {
    this.onSessionExpired.emit(true);
  }

  /**
   * Checks the remaining time for the session timeout cookie.
   * If the time difference is below the specified limit, it executes the logout function.
   */
  private checkTimeOut(): void {
    if (!this.checkSessionTimeOut()) {
      this.handleExpiredSession();
    }
  }
}
