import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class RestHeadersService {
  private readonly xsrfCookieName = 'IV-XSRF-TOKEN'; // TODO read from config

  constructor(private cookieService: CookieService) {}

  /**
   * On all requests which are http methods considered "non-safe" (i.e. not GET, HEAD, OPTIONS and TRACE)
   * by microsoft, the XSRF-Token-Header should be added to the request.
   *
   * Here it is.
   * @returns the headers or undefined
   */
  public getXSRFHeaders(): HttpHeaders | undefined {
    const xsrfCookie = this.cookieService.get(this.xsrfCookieName);
    if (!xsrfCookie) {
      console.warn('No Xsrf cookie found');
      return undefined;
    } else {
      return new HttpHeaders({ 'X-XSRF-TOKEN': xsrfCookie });
    }
  }
}
