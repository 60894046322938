import { isPlatformBrowser } from '@angular/common'
import { PLATFORM_ID, inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router'
import { Observable, map } from 'rxjs'

import { AuthService } from './auth.service'
import { UserInfoService } from './user-info.service'

/**
 * Checks the login state of the user.
 *
 * @param authService - The authentication service.
 * @param userInfoService - The user information service.
 * @returns an observable that emits a boolean value indicating if the user is logged in or not.
 */
function checkLoginState(
    authService: AuthService,
    userInfoService: UserInfoService
): Observable<boolean> {
    return userInfoService.isLoggedIn().pipe(
        map(isLoggedInInternal => {
            if (!isLoggedInInternal) {
                // console.warn('Auth-Guard - not logged in call to handleExpiredSession')
                // authService.handleExpiredSession();
                console.warn('Auth-Guard - Setting HREF: ', authService.LOGIN_URL)
                location.href = authService.LOGIN_URL()
            }

            return isLoggedInInternal
        })
    )
}

/**
 * @returns Observable indicating if user is allowed to access the app.
 */
export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    console.log('AuthGuard called', route.url, state.url);
    const platformId: object = inject(PLATFORM_ID)
    const authService: AuthService = inject(AuthService)
    const userInfoService: UserInfoService = inject(UserInfoService)

    if (isPlatformBrowser(platformId)) {
        return checkLoginState(authService, userInfoService)
    } else {
        // Handle server-side rendering or other platforms
        return new Observable<boolean>(subscriber => {
            subscriber.next(true)
        })
    }
}
