<button *ngIf="!stroked && warn" [attr.cdkFocusInitial]="cdkFocusInitial" mat-flat-button color="warn" [ngClass]="getClass()" [disabled]="disabled" (click)="onClick($event)">
  <mat-icon *ngIf="iconName !== ''" [fontIcon]="iconName"></mat-icon>
  <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>
<button *ngIf="!stroked  && !warn" [attr.cdkFocusInitial]="cdkFocusInitial" mat-flat-button color="primary" [ngClass]="getClass()" [disabled]="disabled" (click)="onClick($event)">
  <mat-icon *ngIf="iconName !== ''" [fontIcon]="iconName"></mat-icon>
  <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>
<button *ngIf="stroked" [attr.cdkFocusInitial]="cdkFocusInitial" mat-stroked-button color="basic" [ngClass]="getClass()" [disabled]="disabled" (click)="onClick($event)">
  <mat-icon *ngIf="iconName !== ''" [fontIcon]="iconName"></mat-icon>
  <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>
<ng-template #buttonContent>
  <ng-content *ngIf="!noText"></ng-content>
</ng-template>
