import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@healthycloud/lib-app-config';
import { Observable } from 'rxjs';

import { IUserBase } from '../models/userBase';
import { IUserDetailed } from '../models/userDetailed';
import { IUserProfile } from '../models/userProfile';
import { RestHeadersService } from '../services/rest-headers.service';

/**
 * Provides access to the userInfo-Endpoint of the TGP. Allows to check the login-state and provides convenience functions
 * to access user name data.
 */
@Injectable({
  providedIn: 'root',
})
export class UserInfoRestService {
  private readonly backendUrl = () => this.appConfigService.tgpUrl;
  private readonly endpoint = '/api/user';
  private readonly endpointImages = '/api/userImage';

  private userUrl = () => this.backendUrl() + this.endpoint;
  public userImageUrl = () => this.backendUrl() + this.endpointImages;

  constructor(private http: HttpClient, private appConfigService: AppConfigService, private restHeaders: RestHeadersService) {}

  /**
   * Get user info .
   * @returns Observable<User>
   */
  public getUserInfo(): Observable<IUserDetailed> {
    return this.http.get<IUserDetailed>(`${this.userUrl()}/userinfo`, {
      withCredentials: true,
    });
  }

  /**
   * Get detailed profile of user.
   * @returns Observable<IUserProfile>
   */
  public getUserProfileById(userId: string): Observable<IUserProfile> {
    return this.http.get<IUserProfile>(`${this.userUrl()}/userprofile/${userId}`, {
      withCredentials: true,
    });
  }

  /**
   * Get base user.
   * @returns Observable<User>
   */
  public getUserBaseById(userId: string): Observable<IUserBase> {
    return this.http.get<IUserBase>(`${this.userUrl()}/userbase/${userId}`, {
      withCredentials: true,
    });
  }

  /**
   * Update existing user.
   * @param user the user to update
   * @returns void
   */
  updateUserDetailed(user: IUserDetailed): Observable<void> {
    return this.http.put<void>(this.userUrl(), user, {
      withCredentials: true,
      headers: this.restHeaders.getXSRFHeaders(),
    });
  }

  /**
   * Updates the current user with the flag if the welcome dialog has been seen
   * @param hasSeenWelcome if true, welcome dialog has been seen by the user. It it set to false the welcome screen will be shown next time again.
   */
  updateUserHasSeenWelcome(hasSeenWelcome: boolean): Observable<void> {
    return this.http.put<void>(`${this.userUrl()}/hasSeenWelcome/`, hasSeenWelcome, {
      withCredentials: true,
      headers: this.restHeaders.getXSRFHeaders(),
    });
  }

  /**
   * Deletes the current user permanetly from the IVP. If the user is still administrator of groups the group names of these groups will be returned
   * @returns array of group names the user is still administator for
   */
  deleteCurrentUser(): Observable<string[]> {
    return this.http.delete<string[]>(this.userUrl(), {
      withCredentials: true,
      headers: this.restHeaders.getXSRFHeaders(),
    });
  }

  /**
   * sends the base64 encoded image to the backend and attaches it to the currentUser
   */
  saveUserImage(imageB64: string): Observable<void> {
    return this.http.post<void>(
      this.userImageUrl(),
      { imageB64 },
      {
        withCredentials: true,
        headers: this.restHeaders.getXSRFHeaders(),
      }
    );
  }

  /**
   * removes the image from the current user
   * @returns void
   */
  deleteUserImage(): Observable<void> {
    return this.http.delete<void>(`${this.userImageUrl()}`, {
      withCredentials: true,
      headers: this.restHeaders.getXSRFHeaders(),
    });
  }
}
